import React, {useState} from "react";
import {Title} from "./title";
import EmblaCarouselReact from "embla-carousel-react";
import styled from "styled-components";
import {colors} from "../styles/variables";

const Quote = styled.p`
    font-style: italic;
    letter-spacing: 0.05em;
    line-height: 1.85em;
    margin: 0 0 20px;
    font-size: 14px;
    text-align: center;
`;

const Author = styled.p`
    letter-spacing: 0.08em;
    font-style: italic;
    margin: 0 0 4.5px;
    line-height: 1em;
    font-weight: 500;
    font-size: 20px;
    color: ${colors.gold};
    text-align: center;
`;

const Button = styled.button`
    border: 1px dashed ${colors.gold};
    display: inline-block;
    position: relative;
    background: 0;
    height: 16px;
    width: 16px;
    margin: 20px 5px;
    &::after {
        display: inline-block;
        background: ${colors.gold};
        position: absolute;
        opacity: ${props => props.selected ? 1 : 0};
        content: "";
        height: 8px;
        width: 8px;
        left: 3px;
        top: 3px;
    }
`;

export const Testimonals = ({testimonials}) => {
    const [embla, setEmbla] = useState(null);
    const scrollTo = React.useCallback(index => embla.scrollTo(index), [embla]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);

    React.useEffect(() => {
        const onSelect = () => {
            setSelectedIndex(embla.selectedScrollSnap());
        };
        if (embla) {
            setScrollSnaps(embla.scrollSnapList());
            embla.on("select", onSelect);
            onSelect();
        }
    }, [embla]);

    return (
        <section className="section section--gradient">
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="section">
                            <Title title={'TESTIMONIALS'}/>
                            <EmblaCarouselReact emblaRef={setEmbla} options={{loop: true}}>
                                <div style={{display: 'flex'}}>
                                    {testimonials.map((testimonial, index) => (
                                        <div style={{flex: '0 0 100%', willChange: 'transform', position: 'relative'}} key={index}>
                                            <Quote>{testimonial.quote}</Quote>
                                            <Author>{testimonial.author}</Author>
                                        </div>
                                    ))}
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    {scrollSnaps.map((snap, index) => (
                                        <Button onClick={() => scrollTo(index)} key={index} selected={index === selectedIndex}/>
                                    ))}
                                </div>
                            </EmblaCarouselReact>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};
