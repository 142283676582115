import React from "react";
import styled from "styled-components";

const Divider = styled.div`
  border-bottom: 1px dashed;
  width: 85px;
  margin: 35px auto;
`;

const Header = styled.h2`
  letter-spacing: 0.26em;
  @media (max-width: 500px) {
    font-size: 1.4rem !important;
  }
`;

export const Title = ({ title }) => (
  <>
    <Header className="title is-size-3 has-text-centered">{title}</Header>
    <Divider />
  </>
);
