import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { colors } from "../styles/variables";
import { Title } from "../components/title";
import { Testimonals } from "../components/testimonals";
import styled from "styled-components";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const StockistsImgs = styled.div`
  max-width: 850px;
  margin: 20px auto 80px;
  display: flex;
`;

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  featureImage,
  testimonials,
  stockists
}) => {
  const PageContent = contentComponent || Content;
  return (
    <>
      <PreviewCompatibleImage
        imageInfo={{ image: featureImage, style: { maxHeight: "50vh" } }}
      />
      <section
        className="section section--gradient"
        style={{ backgroundColor: colors.offWhite }}
      >
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <Title title={title} />
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonals testimonials={testimonials} />
      <Title title={"STOCKISTS"} />
      <StockistsImgs>
        {stockists.map((stockist, index) => (
          <a
            href={stockist.link}
            style={{ margin: "0 auto", width: "40%" }}
            key={index}
          >
              <PreviewCompatibleImage
                  imageInfo={{ image: stockist.image, style: { maxHeight: "50vh", objectFit: 'contain' } }}
                  imgStyle={{objectFit: "contain"}}
              />
          </a>
        ))}
      </StockistsImgs>
    </>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        content={post.html}
        {...post.frontmatter}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        featureImage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        testimonials {
          quote
          author
        }
        stockists {
          image {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          link
        }
      }
    }
  }
`;
